import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"full_statistics"},[_c(VCard,{staticClass:"mb-6"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.t('Estadísticas')))]),_c(VBtn,{staticClass:"no-print mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.printFullSection()}}},[_vm._v(" "+_vm._s(_vm.$t('Imprimir'))+" "),_c(VIcon,{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.icons.mdiPrinter))])],1)],1)]),_c('div',{ref:"evaluation_statistics",staticClass:"evaluation-statistics-container"},[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"statistics-subheader"},[_c('h3',[_vm._v(_vm._s(_vm.$t('Por evaluación')))]),_c('span',[_vm._v("Total: "+_vm._s(_vm.evaluationStatistics.total_evaluations))])]),_c(VSpacer),_c('div',[_c(VBtn,{staticClass:"no-print",attrs:{"color":"primary"},on:{"click":function($event){return _vm.printEvaluationSection()}}},[_vm._v(" "+_vm._s(_vm.$t('Imprimir sección'))+" "),_c(VIcon,{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.icons.mdiPrinter))])],1)],1)],1),_c('div',[_c(VDataTable,{staticClass:"evaluation-table",attrs:{"no-data-text":_vm.$t('No hay datos disponibles.'),"headers":_vm.evaluationColumns,"items":[_vm.evaluationStatistics],"loading":_vm.evaluationLoading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.evaluations_by_country",fn:function(ref){
var item = ref.item;
return _vm._l((item.evaluations_by_country),function(stat,idx){return _c('div',{staticClass:"my-2"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.countryParser[stat.school__region__country__name])+":")]),_vm._v(" "+_vm._s(stat.total)+" ")])])})}},{key:"item.evaluations_by_region",fn:function(ref){
var item = ref.item;
return _vm._l((item.evaluations_by_region),function(stat,idx){return _c('div',{staticClass:"my-2"},[_c('div',[_c('strong',[_vm._v(_vm._s(stat.school__region__name)+":")]),_vm._v(" "+_vm._s(stat.total)+" ")])])})}},{key:"item.evaluations_by_continent",fn:function(ref){
var item = ref.item;
return _vm._l((item.evaluations_by_continent),function(stat,key){return _c('div',{staticClass:"my-2"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.continentParser(key))+":")]),_vm._v(" "+_vm._s(stat)+" ")])])})}}],null,true)})],1)],1)],1),_c('div',{ref:"school_statistics"},[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"statistics-subheader"},[_c('h3',[_vm._v(_vm._s(_vm.$t('Por escuela')))]),_c('span',[_vm._v("Total: "+_vm._s(_vm.schoolStatistics.total_schools))])]),_c(VSpacer),_c('div',[_c(VBtn,{staticClass:"no-print",attrs:{"color":"primary"},on:{"click":function($event){return _vm.printSchoolSection()}}},[_vm._v(" "+_vm._s(_vm.$t('Imprimir sección'))+" "),_c(VIcon,{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.icons.mdiPrinter))])],1)],1)],1),_c(VDataTable,{attrs:{"no-data-text":_vm.$t('No hay datos disponibles.'),"headers":_vm.schoolColumns,"items":[_vm.schoolStatistics],"loading":_vm.schoolLoading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.schools_by_country",fn:function(ref){
var item = ref.item;
return _vm._l((item.schools_by_country),function(stat,idx){return _c('div',{staticClass:"my-2"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.countryParser[stat.region__country__name])+":")]),_vm._v(" "+_vm._s(stat.total)+" ")])])})}},{key:"item.schools_by_region",fn:function(ref){
var item = ref.item;
return _vm._l((item.schools_by_region),function(stat,idx){return _c('div',{staticClass:"my-2"},[_c('div',[_c('strong',[_vm._v(_vm._s(stat.region__name)+":")]),_vm._v(" "+_vm._s(stat.total)+" ")])])})}},{key:"item.schools_by_continent",fn:function(ref){
var item = ref.item;
return _vm._l((item.schools_by_continent),function(stat,key){return _c('div',{staticClass:"my-2"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.continentParser(key))+":")]),_vm._v(" "+_vm._s(stat)+" ")])])})}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }