<template>
  <div ref="full_statistics">
    <v-card class="mb-6">
      <div class="d-flex justify-space-between align-center">
        <v-card-title>{{t('Estadísticas')}}</v-card-title>
        <v-btn class="no-print mr-2" color="primary" @click="printFullSection()">
          {{ $t('Imprimir') }}
          <v-icon class="ml-2">{{ icons.mdiPrinter }}</v-icon>
        </v-btn>
      </div>
    </v-card>

    <div ref="evaluation_statistics" class="evaluation-statistics-container">
      <v-card >
        <v-divider class="mt-4"></v-divider>

        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <div class="statistics-subheader">
            <h3>{{$t('Por evaluación')}}</h3>
            <span>Total: {{ evaluationStatistics.total_evaluations }}</span>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn class="no-print" color="primary" @click="printEvaluationSection()">
              {{ $t('Imprimir sección') }}
              <v-icon class="ml-2">{{ icons.mdiPrinter }}</v-icon>
            </v-btn>
          </div>
        </v-card-text>

        <div>
          <v-data-table
          :no-data-text="$t('No hay datos disponibles.')"
          class="evaluation-table"
          :headers="evaluationColumns"
          :items="[evaluationStatistics]"
          :loading="evaluationLoading"
          disable-pagination
          hide-default-footer
          >
  
          <template #[`item.evaluations_by_country`]="{item}">
            <div v-for="(stat, idx) in item.evaluations_by_country" class="my-2">
              <div>
                <strong>{{ countryParser[stat.school__region__country__name] }}:</strong> {{ stat.total }}
              </div>
            </div>
          </template>
  
          <template #[`item.evaluations_by_region`]="{item}">
            <div v-for="(stat, idx) in item.evaluations_by_region" class="my-2">
              <div>
                <strong>{{ stat.school__region__name }}:</strong> {{ stat.total }}
              </div>
            </div>
          </template>
  
          <template #[`item.evaluations_by_continent`]="{item}">
            <div v-for="(stat, key) in item.evaluations_by_continent" class="my-2">
              <div>
                <strong>{{ continentParser(key) }}:</strong> {{ stat }}
              </div>
            </div>
          </template>
  
          </v-data-table>
        </div>
      </v-card>
    </div>

    <div ref="school_statistics">
      <v-card>
        <v-divider class="mt-4"></v-divider>

        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <div class="statistics-subheader">
            <h3>{{$t('Por escuela')}}</h3>
            <span>Total: {{ schoolStatistics.total_schools }}</span>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn class="no-print" color="primary" @click="printSchoolSection()">
              {{ $t('Imprimir sección') }}
              <v-icon class="ml-2">{{ icons.mdiPrinter }}</v-icon>
            </v-btn>
          </div>
        </v-card-text>

        <v-data-table
        :no-data-text="$t('No hay datos disponibles.')"
        :headers="schoolColumns"
        :items="[schoolStatistics]"
        :loading="schoolLoading"
        disable-pagination
        hide-default-footer
        >

          <template #[`item.schools_by_country`]="{item}">
            <div v-for="(stat, idx) in item.schools_by_country" class="my-2">
              <div>
                <strong>{{ countryParser[stat.region__country__name] }}:</strong> {{ stat.total }}
              </div>
            </div>
          </template>

          <template #[`item.schools_by_region`]="{item}">
            <div v-for="(stat, idx) in item.schools_by_region" class="my-2">
              <div>
                <strong>{{ stat.region__name }}:</strong> {{ stat.total }}
              </div>
            </div>
          </template>

          <template #[`item.schools_by_continent`]="{item}">
            <div v-for="(stat, key) in item.schools_by_continent" class="my-2">
              <div>
                <strong>{{ continentParser(key) }}:</strong> {{ stat }}
              </div>
            </div>
          </template>

        </v-data-table>
      </v-card>
    </div>
    
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import { Printd } from 'printd'
import countriesParserMixin from '@/utils/countriesParserMixin';
import {
  mdiPrinter
} from '@mdi/js'

export default {
  components: {
  
  },
  mixins: [countriesParserMixin],
  data: () => {
    return {
      evaluationLoading: false,
      schoolLoading: false,
      evaluationStatistics: {},
      schoolStatistics: {}
    }
  },
  methods: {
    async getEvaluationStatistics() {
      this.evaluationLoading = true
      try {
        const response = await this.$api.getEvaluationStatistics()
        this.evaluationStatistics = response
        console.log(this.evaluationStatistics)
      } catch(e) {
        console.log(e)
      } finally {
        this.evaluationLoading = false
      }
    },

    async getSchoolStatistics() {
      this.schoolLoading = true
      try {
        const response = await this.$api.getSchoolStatistics()
        this.schoolStatistics = response
        console.log(this.schoolStatistics)
      } catch(e) {
        console.log(e)
      } finally {
        this.schoolLoading = false
      }
    },

    printEvaluationSection() {
      let printContainer = document.createElement('div');

      let statistics = this.$refs.evaluation_statistics.cloneNode(true)
      printContainer.appendChild(statistics)
      this.printer.print(printContainer, [this.printCss])
    },

    printSchoolSection() {
      let printContainer = document.createElement('div');

      let statistics = this.$refs.school_statistics.cloneNode(true)
      printContainer.appendChild(statistics)
      this.printer.print(printContainer, [this.printCss])
    },

    printFullSection() {
      let printContainer = document.createElement('div');
      let titleContainer = document.createElement('div');

      let title = document.createElement('h1');
      title.textContent = `${this.$t('Estadísticas')}`;
      titleContainer.appendChild(title)
      printContainer.appendChild(titleContainer)

      let evaluationStatistics = this.$refs.evaluation_statistics.cloneNode(true)
      let schoolStatistics = this.$refs.school_statistics.cloneNode(true)
      printContainer.appendChild(evaluationStatistics)
      printContainer.appendChild(schoolStatistics)

      this.printer.print(printContainer, [this.printCss])
    },
    continentParser(continent) {
      if(continent === 'America') return `${this.$t('Latinoamerica y el Caribe')}`
      return continent
    }
  },
  computed: {
    evaluationColumns() {
      return [
      { text: this.$t('Por continente'), value: 'evaluations_by_continent', align: 'left', sortable:false},
      { text: this.$t('Por país'), value: 'evaluations_by_country', align: 'left', sortable:false,},
      { text: this.$t('Por región'), value: 'evaluations_by_region', align: 'left', sortable:false},
      ]
    },
    schoolColumns() {
      return [
      { text: this.$t('Por continente'), value: 'schools_by_continent', align: 'left', sortable:false},
      { text: this.$t('Por país'), value: 'schools_by_country', align: 'left', sortable:false,},
      { text: this.$t('Por región'), value: 'schools_by_region', align: 'left', sortable:false},
      ]
    },
  },
  mounted() {
  this.printer = new Printd()
  this.getEvaluationStatistics()
  this.getSchoolStatistics()
  },
  setup() {
    const { t } = useUtils()

        const printCss = `
          h3 {
            margin-bottom: 4px !important
          }

          td {
            padding-right: 200px !important
          }

          th {
            text-align: left !important;
            padding-right: 200px !important;
            padding-bottom: 4px !important
          }

          .statistics-subheader {
            margin-bottom: 8px !important
          }

          .evaluation-statistics-container {
            margin-bottom: 40px !important
          }

          .no-print {
            display: none
          }

          
        `
    return {
      t,
      printCss,
      // icons
      icons: {
        mdiPrinter
      },
    }
  },
}
</script>

<style>

</style>